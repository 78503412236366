import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../Button";
import Details from "../AccountDetails/details";
import { Delete } from "../../../utilities/AccountsService";
import ErrorMessage from "../../ErrorMessage";

export default function AccountCancel(props) {

    const [errorMessage, setErrorMessage] = useState('');

    function CancelAccount() {
        Delete(props.account.id).then(() => {
            props.handleCancelled();
            props.closeModal();
        }).catch(error => {
            console.log(error);
            setErrorMessage(error.response.data);
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>

            <Modal.Header>
                <Modal.Title>Cancel Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong>Are you sure you want to cancel this Account?</strong>
                <br />

                <Details account={props.account} />

                {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></> }

            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between w-100">
                    <Button.Secondary type="button" text="No" onClick={props.closeModal} />
                    <Button.Danger type="button" text="Yes, Cancel" onClick={CancelAccount} />
                </div>
            </Modal.Footer>

        </Modal>
    );
}