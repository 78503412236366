import React, {useState} from "react";
import Button from '../Button';
import EditPaymentMethod from './EditPaymentMethod';

export default function Body(props){

    const [showEditModal, setShowEditModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState({});

    function handleShowEditModal(paymentMethod) {
        setPaymentMethod(paymentMethod);
        setShowEditModal(true);
    }
    function handleCloseEditModal() {
        setShowEditModal(false);
    }

    return (
        <>
            {showEditModal ? <EditPaymentMethod closeModal={handleCloseEditModal} updatedObject={props.updatedObject} paymentMethod={paymentMethod} /> : <></>}
            <tbody>
                {props.paymentMethods.length > 0 ?
                    props.paymentMethods.sort((a, b) => a.title.localeCompare(b.title)).map((paymentMethod) => (
                        <tr key={paymentMethod.id}>
                            <td className="text-center">{paymentMethod.id}</td>
                            <td>{paymentMethod.title}</td>
                            <td className="d-flex justify-content-between">
                                <Button.Secondary type="button" text="Edit" onClick={() => handleShowEditModal(paymentMethod)} />
                                <Button.Danger type="button" text="Delete" onClick={() => props.onDelete(paymentMethod.id)} />
                            </td>
                        </tr>
                    )) : <strong className="text-danger">No payment methods found</strong>}
            </tbody>
        </>
    );
}