import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import ErrorMessage from "../ErrorMessage";
import SuccessMessage from "../SuccessMessage";
import { Get, Delete } from "../../utilities/PeopleService";
import Body from "./body";
import Header from "./header";
import Button from "../Button";
import NewPerson from "./NewPerson";

export default function People() {

    const [people, setPeople] = useState({});
    const [filteredPeople, setFilteredPeople] = useState({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [updates, setUpdates] = useState(0);


    useEffect(() => {
        setLoading(true);
        function getData() {
            Get().then(res => {
                setPeople(res);
                setFilteredPeople(res);
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage('Error at fetching people from API');
                setLoading(false);
            })
        };
        getData();
    }, [updates]);

    function handleDelete(id) {
        Delete(id).then(() => {
            setSuccessMessage("Successfuly deleted person");
            setErrorMessage('');
            setUpdates(updates + 1);
        }).catch(error => {
            console.log(error);
            setErrorMessage(error.response.data);
            setSuccessMessage('');
        });
    }

    function handleShowCreateModal() {
        setShowCreateModal(true);
    }
    function handleCloseCreateModal() {
        setShowCreateModal(false);
    }
    function handleCreatedPerson() {
        setErrorMessage('');
        setSuccessMessage('Successfuly created new person!');
        setUpdates(updates + 1);
    }
    function handleUpdatedPerson() {
        setErrorMessage('');
        setSuccessMessage('Successfuly updated person!');
        setUpdates(updates + 1);
    }
    function handleSearch(e) {
        if (e.target.value === null || e.target.value === "") {
            setFilteredPeople(people);
        } else {
            setFilteredPeople(
                people.filter((item) => item.name.toUpperCase().includes(e.target.value.toUpperCase()))
            );
        }
    }

    return (
        loading ? <Loading text="Loading..." />
            : <main className="container">
                {showCreateModal ? <NewPerson closeModal={handleCloseCreateModal} createdNewPerson={handleCreatedPerson} /> : <></>}
                {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></>}
                {successMessage ? <SuccessMessage msg={successMessage} /> : <></>}
                <div className="w-25">
                    <Button.Primary type="button" text="New Person" disabled={false} onClick={handleShowCreateModal} />
                </div>
                <div className="mt-3 col-md-6">
                    <div className="form-group mb-3">
                        <input type="text" name="search" id="search" className="form-control form-control-sm" placeholder="Search" onChange={handleSearch} />
                    </div>
                </div>
                <table className="table">
                    <Header count={people.length} />
                    <Body people={filteredPeople} onDelete={handleDelete} updatedPerson={handleUpdatedPerson} />
                </table>
            </main>

    );

}