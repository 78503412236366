import React, { useEffect, useState } from "react";
import { GetById } from "../../../utilities/TransactionsService";
import Loading from "../../Loading";
import ErrorMessage from "../../ErrorMessage";
import SuccessMessage from "../../SuccessMessage";
import Items from "./Items";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Payments from "../../Payments";
import EditTransaction from "./EditTransaction";
import { GetType, GetPersonTypeRequired } from "../../../utilities/Enums/TransactionTypeEnum";

export default function TransactionDetails(props) {

    const id = props.id;
    const [loading, setLoading] = useState(true);
    const [transaction, setTransaction] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [updates, setUpdates] = useState(0);
    const [showEditModal, setShowEditModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        function getData() {
            GetById(id).then(res => {
                setTransaction(res);
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                setTransaction(undefined);
                console.log(error);
                setErrorMessage('Error at fetching data from API');
                setLoading(false);
            });
        };
        getData();
    }, [id, updates]);

    function handleDeletedItem() {
        setErrorMessage('');
        setSuccessMessage('Successfuly item deleted');
        setUpdates(updates + 1);
    }

    function handleShowEditModal() {
        setShowEditModal(true);
    }
    function handleCloseEditModal() {
        setShowEditModal(false);
    }
    function handleUpdated() {
        setSuccessMessage('Successfuly updated transaction');
        setErrorMessage('');
        setUpdates(updates + 1);
    }

    function handleCreatedItem() {
        setSuccessMessage('Successfuly added item');
        setErrorMessage('');
        setUpdates(updates + 1);
    }
    function handleUpdatedItem() {
        setSuccessMessage('Successfuly updated item');
        setErrorMessage('');
        setUpdates(updates + 1);
    }
    function handleCreatedPayment() {
        setSuccessMessage('Successfuly created payment');
        setErrorMessage('');
        setUpdates(updates + 1);
    }
    function handleDeletedPayment() {
        setSuccessMessage('Successfuly deleted payment');
        setErrorMessage('');
        setUpdates(updates + 1);
    }
    function handlePaid() {
        setSuccessMessage("Successfuly paid account");
        setErrorMessage('');
        setUpdates(updates + 1);
    }
    function handleCancelled() {
        setSuccessMessage("Successfuly cancelled account");
        setErrorMessage('');
        setUpdates(updates + 1);
    }

    return (
        loading ? <Loading text="Loading..." /> :
            <main className="container">
                {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></>}
                {successMessage ? <SuccessMessage msg={successMessage} /> : <></>}



                {transaction ?
                    <>
                        {showEditModal ? <EditTransaction closeModal={handleCloseEditModal} handleUpdated={handleUpdated} transaction={transaction} /> : <></>}
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <h5 className="text-center mt-1 mb-3">{GetType(transaction.type)} Details</h5>
                                <div className="d-flex justify-content-between">
                                    <div className="col-md-6">
                                        <strong>{GetPersonTypeRequired(transaction.type)}: </strong><span>{transaction.person.name}</span>
                                        <br />
                                        <strong>{GetType(transaction.type)} Date: </strong><span>{new Date(transaction.date).toLocaleDateString()}</span>
                                        <br />
                                        <button type="button" className="btn btn-primary btn-sm col-md-6 mt-2" onClick={handleShowEditModal}>Edit</button>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <strong>Items: </strong><span>${transaction.totalItems.toFixed(2)}</span>
                                        <br />
                                        <strong>Shipping Cost: </strong><span>${transaction.shippingCost.toFixed(2)}</span>
                                        <br />
                                        <strong>Discount: </strong><span>${transaction.discount.toFixed(2)}</span>
                                        <br />
                                        <strong>Total Value: </strong><span>${transaction.totalValue.toFixed(2)}</span>
                                    </div>
                                </div>

                                <Tabs defaultActiveKey="items" className="mt-4">
                                    <Tab eventKey="items" title="Items">
                                        <Items transaction={transaction} handleDeleted={handleDeletedItem} handleUpdated={handleUpdatedItem} handleCreated={handleCreatedItem} setErrorMessage={setErrorMessage} />
                                    </Tab>
                                    <Tab eventKey="payments" title="Payments">
                                        <Payments payments={transaction.payments} transactionId={transaction.id} handleCreated={handleCreatedPayment} handleDeleted={handleDeletedPayment} handlePaid={handlePaid} handleCancelled={handleCancelled} />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>

                    </> : <></>}
            </main>
    );
}