import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import EditPerson from "./EditPerson";
import GetTypeString from "../../utilities/PersonType";

export default function Body(props) {

    const [showEditModal, setShowEditModal] = useState(false);
    const [person, setPerson] = useState({});

    function handleShowEditModal(person) {
        setPerson(person);
        setShowEditModal(true);
    }
    function handleCloseEditModal() {
        setShowEditModal(false);
    }

    return (
        <>
            {showEditModal ? <EditPerson closeModal={handleCloseEditModal} updatedPerson={props.updatedPerson} person={person} /> : <></>}
            <tbody>
                {props.people.length > 0 ?
                    props.people.sort((a, b) => a.name.localeCompare(b.name)).map((person) => (
                        <tr key={person.id}>
                            <td className="text-center">{person.id}</td>
                            <td>{person.name}</td>
                            <td className="text-center">{person.cpf}</td>
                            <td className="text-center">{person.cnpj}</td>
                            <td className="text-center">{GetTypeString({type: person.type})}</td>
                            <td className="d-flex justify-content-between">
                                <Link className='btn btn-primary btn-sm w-100' to={`/person-details/${person.id}`}>
                                    Details
                                </Link>
                                <Button.Secondary type="button" text="Edit" onClick={() => handleShowEditModal(person)} />
                                <Button.Danger type="button" text="Delete" onClick={() => props.onDelete(person.id)} />
                            </td>
                        </tr>
                    )) : <strong className="text-danger">No people found</strong>}
            </tbody>
        </>
    );
}