import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Put } from "../../../utilities/CategoriesService";
import Input from "../../Input";
import Button from "../../Button";

export default function EditCategory(props){

    const [category, setCategory] = useState(props.category);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function updateObject() {
        setSaving(true);
        Put(category.id, category).then(() => {
            props.updatedObject();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage('Error at fetching data from the api.');
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        updateObject();
    }

    const handleChange = (e) => {
        setCategory({
            ...category,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>Edit Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Input.Text name="title" id="title" label="Title" defaultValue={category.title} onChange={handleChange} />

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}