export const AccountItemStatus = {
    Paid: 2,
    Cancelled: 3
}

export const GetItemStatus = (status) => {
    let text = '';
    switch (status) {
        case AccountItemStatus.Paid:
            text = "Paid";
            break;
        case AccountItemStatus.Cancelled:
            text = "Cancelled";
            break;
        default:
            text = "";
            break;
    }
    return text;
}