import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import EditProduct from "./EditProduct";

export default function Body(props) {

    const [showEditModal, setShowEditModal] = useState(false);
    const [product, setProduct] = useState({});

    function handleShowEditModal(product) {
        setProduct(product);
        setShowEditModal(true);
    }
    function handleCloseEditModal() {
        setShowEditModal(false);
    }

    return (
        <>
            {showEditModal ? <EditProduct closeModal={handleCloseEditModal} updatedProduct={props.updatedProduct} product={product} /> : <></>}
            <tbody>
                {props.products.length > 0 ?
                    props.products.sort((a, b) => a.title.localeCompare(b.title)).map((product) => (
                        <tr key={product.id}>
                            <td className="text-center">{product.id}</td>
                            <td>{product.title}</td>
                            <td>{product.category.title}</td>
                            <td className="text-center">${product.price.toFixed(2)}</td>
                            <td className="d-flex justify-content-between">
                                <Link className='btn btn-primary btn-sm w-100' to={`/product-details/${product.id}`}>
                                    Details
                                </Link>
                                <Button.Secondary type="button" text="Edit" onClick={() => handleShowEditModal(product)} />
                                <Button.Danger type="button" text="Delete" onClick={() => props.onDelete(product.id)} />

                            </td>
                        </tr>
                    )) : <strong className="text-danger">No products found</strong>}
            </tbody>
        </>
    );
}