import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetById } from "../../../utilities/ProductsService";
import SuccessMessage from "../../SuccessMessage";
import ErrorMessage from "../../ErrorMessage";
import Loading from "../../Loading";


export default function ProductDetails() {

    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        function getData() {
            try {
                GetById(id).then(res => {
                    setProduct(res);
                    setErrorMessage('');
                    setSuccessMessage('');
                    setLoading(false);
                });
            } catch (error) {
                console.log(error);
                setErrorMessage('Error at fetching product from API');
                setSuccessMessage('');
                setLoading(false);
            }
        };
        getData();
    }, [id]);

    return (
        loading ? <Loading text="Loading..." /> :
            <main className="container">
                {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></>}
                {successMessage ? <SuccessMessage msg={successMessage} /> : <></>}

                {product ?
                    <>
                        <div className="card shadow-lg m-4">
                            <div className="card-body">
                                <strong>Product: </strong><span>{product.title}</span>
                                <br />
                                <strong>Category: </strong><span>{product.category.title}</span>
                            </div>
                        </div>
                    </> : <></>}

            </main>
    );
}