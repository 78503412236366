import React, { useState } from "react";
import Body from "./body";
import Header from "./header";
import NewTransactionItem from "../NewTransactionItem";

export default function Items(props) {


    const [showNewItemModal, setShowNewItemModal] = useState(false);

    function handleShowNewItemModal() {
        setShowNewItemModal(true);
    }
    function handleCloseNewItemModal() {
        setShowNewItemModal(false);
    }


    return (
        <>
            {showNewItemModal ? <NewTransactionItem closeModal={handleCloseNewItemModal} handleCreated={props.handleCreated} transactionId={props.transaction.id} /> : <></>}
            <br/>
            <button type="button" className="btn btn-primary btn-sm col-md-3 mt-2" onClick={handleShowNewItemModal}>Add New Item</button>
            <table className="table mt-4">
                <Header count={props.transaction.items.length} totalValue={props.transaction.totalValue} />
                <Body transactionItems={props.transaction.items} handleDeleted={props.handleDeleted} handleUpdated={props.handleUpdated} setErrorMessage={props.setErrorMessage} />
            </table>
        </>
    );
}