import React from "react";
import { useState } from "react";
import { Login as LoginService } from "../../utilities/UsersService";
import { useNavigate } from "react-router-dom";

export default function Login(props) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    let navigate = useNavigate();

    function handleLogin() {
        setLoading(true);
        LoginService(credentials)
            .then(function (response) {
                setErrorMessage('');
                setLoading(false);
                localStorage.clear();
                localStorage.setItem('userToken', response.token);
                localStorage.setItem('loggedIn', true);
                props.setLogin(true);
                navigate('/');
            })
            .catch(function (error) {
                if (error.response) {
                    setErrorMessage(error.response.data);
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    setErrorMessage(error.request);
                    console.log(error.request);
                } else {
                    setErrorMessage(error.message);
                    console.log(error.message);
                }
                setLoading(false);
            });
    }

    function handleForm(e) {
        e.preventDefault();
        handleLogin();
    }
    function handleChange(e) {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <div className="w-100 row justify-content-center align-items-center form-center">
                <div className="card rounded-3">
                    <div className="card-body ">
                        <div className="text-center mb-4">
                            <div className="p-4 bg-dark">
                                <h4>Financial Credentials</h4>
                            </div>
                        </div>
                        <form onSubmit={handleForm}>
                            {errorMessage ? <span className="text-danger">{errorMessage}</span> : <></>}

                            <div className="form-floating form-outline mb-4">
                                <input type="email" id="email" name="email" className="form-control" placeholder="Email"
                                    required onChange={handleChange} />
                                <label className="form-label" htmlFor="email">Email</label>
                            </div>

                            <div className="form-floating form-outline mb-4">
                                <input type="password" id="password" name="password" className="form-control" placeholder="Password"
                                    required onChange={handleChange} />
                                <label className="form-label" htmlFor="password">Password</label>
                            </div>

                            <div className="text-center pt-1 mb-5 pb-1">
                                {loading ?
                                    <button className="btn btn-primary btn-block fa-lg mb-3 w-100" disabled="disabled" type="button">Loading...</button>
                                    : <button className="btn btn-primary btn-block fa-lg mb-3 w-100" type="submit">Login</button>}
                            </div>
                        </form>
                    </div>
                </div>
        </div>
    );
}