import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Put } from "../../../../utilities/TransactionsService";
import { GetCustomers, GetSuppliers } from "../../../../utilities/PeopleService";
import Input from "../../../Input";
import Button from "../../../Button";
import { FormatDate } from "../../../../utilities/Methods";
import { TransactionType, GetType, GetPersonTypeRequired } from "../../../../utilities/Enums/TransactionTypeEnum";

export default function EditTransaction(props) {

    const [transaction, setTransaction] = useState({});
    const [people, setPeople] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setTransaction(props.transaction);
        if (props.transaction.type === TransactionType.Purchase) {
            GetSuppliers().then(res => {
                setPeople(res.sort((a, b) => a.name.localeCompare(b.name)));
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage('Error at fetching suppliers from API');
                setLoading(false);
            });
        } else if (props.transaction.type === TransactionType.Sale) {
            GetCustomers().then(res => {
                setPeople(res.sort((a, b) => a.name.localeCompare(b.name)));
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage('Error at fetching customers from API');
                setLoading(false);
            });
        }

    }, [props.transaction]);

    function UpdateTransaction() {
        setSaving(true);
        Put(transaction.id, transaction).then(() => {
            props.handleUpdated();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        UpdateTransaction();
    }

    const handleChange = (e) => {
        setTransaction({
            ...transaction,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>Edit {GetType(transaction.type)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {loading ? <Loading text='Loading...' /> : <>
                        <div className="form-group form-floating mb-3">
                            <select className="form-select" name="personId" id="personId" defaultValue={transaction.person.id} onChange={handleChange}>
                                {people.map((person) => (
                                    <option key={person.id} value={person.id}>{person.name}</option>
                                ))}
                            </select>
                            <label htmlFor="personId" className="form-label">{GetPersonTypeRequired(transaction.type)}</label>
                        </div>
                        <Input.Date name="date" id="date" label={GetType(transaction.type) + " Date"} defaultValue={FormatDate(new Date(transaction.date))} onChange={handleChange} />
                        <Input.Number name="discount" id="discount" label="Discount" defaultValue={transaction.discount} onChange={handleChange} />
                        <Input.Number name="shippingCost" id="shippingCost" label="Shipping Cost" defaultValue={transaction.shippingCost} onChange={handleChange} />
                    </>}

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}