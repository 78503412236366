import React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { AccountStatus } from "../../utilities/Enums/AccountStatusEnum";
import { SumProperty } from "../../utilities/Methods";

export default function Chart(props) {

    const accountsPaid = props.accounts.filter((item) => item.status === AccountStatus.Paid || item.status === AccountStatus.Partial);
    const accountsCancelled = props.accounts.filter((item) => item.status === AccountStatus.Cancelled);
    const accountsAwaiting = props.accounts.filter((item) => item.status === AccountStatus.Awaiting);

    const totalPaid = SumProperty(accountsPaid, "paidValue");
    const totalDiscount = SumProperty(accountsPaid, "discount");
    const totalAwaiting = SumProperty(accountsAwaiting, "value");
    const totalRemaining = SumProperty(accountsPaid, "value") - totalPaid - totalDiscount + totalAwaiting;
    const totalCancelled = SumProperty(accountsCancelled, "value");

    const data = [
        { name: "Discount", value: totalDiscount },
        { name: "Paid", value: totalPaid },
        { name: "Remaining", value: totalRemaining },
        { name: "Cancelled", value: totalCancelled },
    ];

    const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <PieChart >
                    <Pie data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value">
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}