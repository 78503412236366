export const FormatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const SumQuantities = (array) => {
    let result = 0;
    array.forEach(element => {
        result += element.quantity;
    });
    return result;
}

export const SumTotalCost = (array) => {
    let result = 0;
    array.forEach(element => {
        result += element.totalCost;
    });
    return result;
}

export const SumTotalPrice = (array) => {
    let result = 0;
    array.forEach(element => {
        result += element.totalPrice;
    });
    return result;
}

export const SumTotalValue = (array) => {
    let result = 0;
    array.forEach(element => {
        result += element.totalValue;
    });
    return result;
}

export const SumValue = (array) => {
    let result = 0;
    array.forEach(element => {
        result += element.value;
    });
    return result;
}

export const SumProperty = (array, property) => {
    let result = 0;
    array.forEach(element => {
        result += element[property];
    });
    return result;
}

/*
export const CheckEmptyObject = (obj) => {
    if (typeof obj === 'object' && obj != null && Object.keys(obj).length !== 0){
        return false;
    } else {
        return true;
    }
}
*/