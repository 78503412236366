import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState } from 'react';
import Home from './components/Home';
import Header from './components/Header';
import Login from './components/Login';
import NotFound from './components/NotFound';
import Products from './components/Products';
import ProductDetails from './components/Products/ProductDetails';
import People from './components/People';
import PersonDetails from './components/People/PersonDetails';
import Purchases from './components/Purchases';
import PurchaseDetails from './components/Purchases/PurchaseDetails';
import Sales from './components/Sales';
import SaleDetails from './components/Sales/SaleDetails';
import PaymentMethods from './components/PaymentMethods';
import Financial from './components/Financial';
import Categories from './components/Categories';

export default function App() {
  const [login, setLogin] = useState(localStorage.getItem('loggedIn'));

  return (
    <Router>
      <>
        <Header login={login} setLogin={setLogin} />
        <Routes>
          <Route index path='/' element={<Home />} />
          {login ? <>
            <Route path='/products' element={<Products />} />
            <Route path='/product-details/:id' element={<ProductDetails />} />
            <Route path='/people' element={<People />} />
            <Route path='/person-details/:id' element={<PersonDetails />} />
            <Route path='/purchases' element={<Purchases />} />
            <Route path='/purchase-details/:id' element={<PurchaseDetails />} />
            <Route path='/sales' element={<Sales />} />
            <Route path='/sale-details/:id' element={<SaleDetails />} />
            <Route path='/paymentmethods' element={<PaymentMethods />} />
            <Route path='/accounts' element={<Financial />} />
            <Route path='/categories' element={<Categories />} />
          </> : <>
            <Route path='/login' element={<Login setLogin={setLogin} />} />
          </>}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </>
    </Router>
  );
}
