import React from "react";

export default function Header(props){
    return(
        <thead>
            <tr>
                <th colSpan="3">Categories Count: {props.count}</th>
            </tr>
            <tr>
                <th className="text-center">Id</th>
                <th>Title</th>
                <th></th>
            </tr>
        </thead>
    );
}