import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Post } from "../../../utilities/TransactionsService";
import { GetSuppliers, GetCustomers } from "../../../utilities/PeopleService";
import Input from "../../Input";
import Button from '../../Button';
import { FormatDate } from "../../../utilities/Methods";
import { TransactionType, GetType, GetPersonTypeRequired } from "../../../utilities/Enums/TransactionTypeEnum";

export default function NewTransaction(props) {

    const today = FormatDate(new Date());

    const emptyObject = Object.freeze({
        personId: 0,
        date: today,
        type: props.type
    });

    const [transaction, setTransaction] = useState(emptyObject);
    const [people, setPeople] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        function getData() {
            if (props.type === TransactionType.Purchase) {
                GetSuppliers().then(res => {
                    setPeople(res.sort((a, b) => a.name.localeCompare(b.name)));
                    setErrorMessage('');
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('Error at fetching suppliers from API');
                    setLoading(false);
                });
            } else if (props.type === TransactionType.Sale) {
                GetCustomers().then(res => {
                    setPeople(res.sort((a, b) => a.name.localeCompare(b.name)));
                    setErrorMessage('');
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('Error at fetching customers from API');
                    setLoading(false);
                });
            }
        };
        getData();
    }, [props.type]);

    function CreateNewTransaction() {
        setSaving(true);
        Post(transaction).then(() => {
            props.handleCreated(true);
            props.closeModal();
        }).catch(error => {
            if (error.response.status === 400) {
                setErrorMessage(JSON.stringify(error.response.data));
            }
            setSaving(false);
        });
    }

    function handleCreateForm(e) {
        e.preventDefault();
        CreateNewTransaction();
    }

    const handleChange = (e) => {
        setTransaction({
            ...transaction,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleCreateForm}>
                <Modal.Header>
                    <Modal.Title>New {GetType(props.type)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {loading ? <Loading text="Loading..." />
                        : people && people.length > 0 ?
                            <>
                                <div className="form-group form-floating mb-3">
                                    <select className="form-select" name="personId" id="personId" onChange={handleChange}>
                                        <option value="0">Select...</option>
                                        {people.map((person) => (
                                            <option key={person.id} value={person.id}>{person.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="personId" className="form-label">{GetPersonTypeRequired(props.type)}</label>
                                </div>

                                <Input.Date name="date" id="date" label={GetType(props.type) + " Date"} defaultValue={today} onChange={handleChange} />
                            </> : <></>}

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );

}