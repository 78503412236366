import Constants from "./Constants";
import { GetToken } from "./TokenService";
import axios from 'axios';

export const Post = async (payment) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.post(Constants.API_CREATE_PAYMENT, payment, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Delete = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.delete(Constants.API_DELETE_PAYMENT + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};