import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Post } from "../../../../utilities/TransactionItemsService";
import { Get } from "../../../../utilities/ProductsService";
import Input from "../../../Input";
import Button from "../../../Button";

export default function NewTransactionItem(props) {

    const emptyObject = Object.freeze({
        transactionId: props.transactionId,
        productId: 0,
        quantity: 0,
        unitPrice: 0
    });

    const [transactionItem, setTransactionItem] = useState(emptyObject);
    const [products, setProducts] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [currentPrice, setCurrentPrice] = useState(0);

    useEffect(() => {
        function getData() {
            try {
                Get().then(res => {
                    setProducts(res.sort((a, b) => a.title.localeCompare(b.title)));
                    setErrorMessage('');
                    setLoading(false);
                });
            } catch (error) {
                console.log(error);
                setErrorMessage(error.response.data);
                setLoading(false);
            }
        };
        getData();
    }, []);

    function CreateItem() {
        setSaving(true);
        Post(transactionItem).then(() => {
            props.handleCreated();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        CreateItem();
    }

    const handleChange = (e) => {
        if (e.target.name === "productId"){
            setCurrentPrice(products.find(item => item.id === parseInt(e.target.value)).price);
        }
        setTransactionItem({
            ...transactionItem,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>New Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {loading ? <Loading text='Loading...' /> : <>
                        
                        <div className="form-group form-floating mb-1">
                            <select className="form-select" name="productId" id="productId" onChange={handleChange}>
                                <option value="0">Select...</option>
                                {products.map((product) => (
                                    <option value={product.id} key={product.id}>{product.title}</option>
                                ))}
                            </select>
                            <label htmlFor="productId" className="form-label">Product</label>
                        </div>
                        <div className="form-group form-floating mb-3">
                            <strong>Currente Price: </strong><span>${currentPrice.toFixed(2)}</span>
                        </div>

                        <Input.Number name="quantity" id="quantity" label="Quantity" onChange={handleChange} />
                        <Input.Number name="unitPrice" id="unitPrice" label="Unit Price" onChange={handleChange} />

                    </>}

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}