import React, { useState } from "react";
import { GetType } from "../../utilities/Enums/AccountTypeEnum";
import { AccountStatus, GetStatus } from "../../utilities/Enums/AccountStatusEnum";
import AccountDetails from "../Accounts/AccountDetails";
import AccountPay from "../Accounts/AccountPay";
import AccountCancel from "../Accounts/AccountCancel";
import AccountPayMultiple from "./AccountPayMultiple";
import AccountCancelMultiple from "./AccountCancelMultiple";

export default function Accounts(props) {

    const [account, setAccount] = useState(0);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showPayModal, setShowPayModal] = useState(false);
    const [showPayMultipleModal, setShowPayMultipleModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showCancelMultipleModal, setShowCancelMultipleModal] = useState(false);
    const [checkedState, setCheckedState] = useState(
        new Array(props.accounts.length).fill(false)
    );
    const [checkAll, setCheckAll] = useState(false);
    const [accountsSelected, setAccountsSelected] = useState({});

    function handleShowDetailsModal(account) {
        setAccount(account);
        setShowDetailsModal(true);
    }
    function handleCloseDetailsModal() {
        setShowDetailsModal(false);
    }

    function handleShowCancelModal(account) {
        if (account.status === AccountStatus.Cancelled) {
            props.setErrorMessage("The account is already cancelled");
        } else {
            setAccount(account);
            setShowCancelModal(true);
        }
    }
    function handleCloseCancelModal() {
        setShowCancelModal(false);
    }

    function handleShowPayModal(account) {
        if (account.status === AccountStatus.Paid) {
            props.setErrorMessage("The account is already paid");
        } else if (account.status === AccountStatus.Cancelled) {
            props.setErrorMessage("The account is cancelled");
        } else {
            setAccount(account);
            setShowPayModal(true);
        }
    }
    function handleClosePayModal() {
        setShowPayModal(false);
    }

    function handleCheckBoxChange(position) {
        let updatedCheckedState = checkedState.map((item, index) => index === position ? !item : item);
        let updatedAccountsSelected = props.accounts.filter((item, index) => updatedCheckedState[index]);
        setAccountsSelected(updatedAccountsSelected);
        setCheckedState(updatedCheckedState);
    }
    function handleShowPayMultiple() {
        setShowPayMultipleModal(true);
    }
    function handleClosePayMultiple() {
        setShowPayMultipleModal(false);
    }
    function handleShowCancelMultiple() {
        setShowCancelMultipleModal(true);
    }
    function handleCloseCancelMultiple() {
        setShowCancelMultipleModal(false);
    }
    function handleSelectedAllChange() {
        let updatedCheckedState = checkedState.map(() => !checkAll);
        let updatedAccountsSelected = props.accounts.filter((item, index) => updatedCheckedState[index]);
        setCheckedState(updatedCheckedState);
        setAccountsSelected(updatedAccountsSelected);
        setCheckAll(!checkAll);
    }

    return (
        <>
            {showDetailsModal ? <AccountDetails closeModal={handleCloseDetailsModal} account={account} /> : <></>}
            {showPayModal ? <AccountPay closeModal={handleClosePayModal} account={account} handlePaid={props.handlePaid} /> : <></>}
            {showCancelModal ? <AccountCancel closeModal={handleCloseCancelModal} account={account} handleCancelled={props.handleCancelled} /> : <></>}
            {showPayMultipleModal ? <AccountPayMultiple closeModal={handleClosePayMultiple} accounts={accountsSelected} handlePaid={props.handlePaid} /> : <></>}
            {showCancelMultipleModal ? <AccountCancelMultiple closeModal={handleCloseCancelMultiple} accounts={accountsSelected} handleCancelled={props.handleCancelled} /> : <></>}
            <table className="table">
                <thead>
                    <tr>
                        <th className="text-center">
                            <button type="button" className="btn btn-sm" onClick={handleShowPayMultiple}>
                                <img className="small-icon" src="../img/check.png" title="Pay" alt="Pay" />
                            </button>
                            <button type="button" className="btn btn-sm" onClick={handleShowCancelMultiple}>
                                <img className="small-icon" src="../img/x-button.png" title="Cancel" alt="Cancel" />
                            </button>
                            <br />
                            <input type="checkbox" className="form-check-input mt-4"
                                id="selectAll" name="selectAll" value="selectAll"
                                checked={checkAll}
                                onChange={handleSelectedAllChange} />
                        </th>
                        <th>Id</th>
                        <th>Type</th>
                        <th>Due Date</th>
                        <th>Parcel</th>
                        <th>Value</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.accounts && props.accounts.length > 0 ? props.accounts.sort((a, b) => a.dueDate.localeCompare(b.dueDate)).map((account, index) => (
                        <tr key={account.id}>
                            <td className="text-center">
                                <input type="checkbox" className="form-check-input"
                                    id={`account-${account.id}`} name="accounts" value="accounts"
                                    checked={checkedState[index]}
                                    onChange={() => handleCheckBoxChange(index)} />
                            </td>
                            <td>{account.id}</td>
                            <td>{GetType(account.type)}</td>
                            <td>{new Date(account.dueDate).toLocaleDateString()}</td>
                            <td>Parcel {account.parcel}{account.payment ? "/" + account.payment.parcels : ""}</td>
                            <td>${account.value.toFixed(2)}</td>
                            <td>{GetStatus(account.status)}</td>
                            <td>
                                <button type="button" className="btn btn-sm" onClick={() => handleShowDetailsModal(account)}>
                                    <img className="small-icon" src="../img/info.png" title="Details" alt="Details" />
                                </button>
                                <button type="button" className="btn btn-sm" onClick={() => handleShowPayModal(account)}>
                                    <img className="small-icon" src="../img/check.png" title="Pay" alt="Pay" />
                                </button>
                                <button type="button" className="btn btn-sm" onClick={() => handleShowCancelModal(account)}>
                                    <img className="small-icon" src="../img/x-button.png" title="Cancel" alt="Cancel" />
                                </button>
                            </td>
                        </tr>
                    )) : <></>}
                </tbody>
            </table>
        </>

    );
}