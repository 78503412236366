import React from "react";
import { useParams } from "react-router-dom";
import TransactionDetails from "../../Transactions/TransactionDetails";

export default function Purchases(){

    const { id } = useParams();

    return (
        <TransactionDetails id={id} />
    );
}