import Constants from "./Constants";
import { GetToken } from "./TokenService";
import axios from 'axios';

export const Get = async () => {
    const token = GetToken(Constants.APP_TOKEN);
    let response = {};
    try {
        response = await axios.get(Constants.API_GET_ALL_USERS, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const GetById = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    let response = {};
    try {
        response = await axios.get(Constants.API_GET_USER_BY_ID + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const Post = async (user) => {
    try {
        await axios.post(Constants.API_CREATE_USER, user);
    } catch (error) {
        throw error;
    }
};

export const Login = async (credentials) => {
    let response = {};
    try {
        response = await axios.post(Constants.API_USER_LOGIN, credentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const Put = async (id, user) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.put(Constants.API_UPDATE_USER + id, user, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Activate = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.put(Constants.API_ACTIVATE_USER + id + "/activate", {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Delete = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.delete(Constants.API_DELETE_USER + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};