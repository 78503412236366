import React from "react";
import {GetPersonTypeRequired} from "../../utilities/Enums/TransactionTypeEnum";

export default function Header(props){
    return (
        <thead>
        <tr>
            <th colSpan="2">Count: {props.count}</th>
            <th colSpan="3" className="text-end">Total: ${props.totalValue.toFixed(2)}</th>
        </tr>
        <tr>
            <th className="text-center">Id</th>
            <th className="text-center">Date</th>
            <th className="text-center">{GetPersonTypeRequired(props.type)}</th>
            <th className="text-center">Total</th>
            <th></th>
        </tr>
    </thead>
    );
}