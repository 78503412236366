import React, { useState, useEffect } from "react";
import { Get, Delete } from '../../utilities/PaymentMethodsService';
import Loading from "../Loading";
import SuccessMessage from "../SuccessMessage";
import ErrorMessage from "../ErrorMessage";
import Button from "../Button";
import Header from "./header";
import Body from "./body";
import NewPaymentMethod from "./NewPaymentMethod";

export default function PaymentMethods() {

    const [paymentMethods, setPaymentMethods] = useState({});
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        function GetData() {
            Get().then(res => {
                setPaymentMethods(res);
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage('Error at fetching payment methods from API');
                setSuccessMessage('');
                setLoading(false);
            });
        };
        GetData();
    }, [updates]);

    function handleDelete(id) {
        Delete(id).then(() => {
            setSuccessMessage("Successfuly deleted payment method");
            setErrorMessage('');
            setUpdates(updates + 1);
        }).catch(error => {
            console.log(error);
            setErrorMessage(error.response.data);
            setSuccessMessage('');
        });
    }

    function handleShowCreateModal() {
        setShowCreateModal(true);
    }
    function handleCloseCreateModal() {
        setShowCreateModal(false);
    }
    function handleCreated() {
        setErrorMessage('');
        setSuccessMessage('Successfuly created new payment method!');
        setUpdates(updates + 1);
    }
    function handleUpdated() {
        setErrorMessage('');
        setSuccessMessage('Successfuly updated payment method!');
        setUpdates(updates + 1);
    }

    return (
        loading ? <Loading text="Loading..." />
            : <main className="container">
                {showCreateModal ? <NewPaymentMethod closeModal={handleCloseCreateModal} createdNewObject={handleCreated} /> : <></>}
                {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></>}
                {successMessage ? <SuccessMessage msg={successMessage} /> : <></>}
                <div className="w-25">
                    <Button.Primary type="button" text="New Payment Method" disabled={false} onClick={handleShowCreateModal} />
                </div>
                <table className="table mt-3">
                    <Header count={paymentMethods.length} />
                    <Body paymentMethods={paymentMethods} onDelete={handleDelete} updatedObject={handleUpdated} />
                </table>
            </main>
    );
}