import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Pay } from "../../../utilities/AccountsService";
import Loading from "../../Loading";
import Input from "../../Input";
import Button from "../../Button";
import { FormatDate, SumProperty } from "../../../utilities/Methods";

export default function AccountPayMultiple(props) {

    const today = FormatDate(new Date());
    const payAccounts = props.accounts;

    const [date, setDate] = useState(today);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    function post() {
        setSaving(true);
        const postObject = {
            accountsId: payAccounts.map((item) => item.id), 
            date: date
        };
        Pay(postObject).then(() => {
            props.handlePaid();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        post();
    }

    const handleChange = (e) => {
        setDate(e.target.value);
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>Pay Multiple Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {payAccounts.length > 0 ?
                        <>
                            <div className="form-group shadow mb-3">
                                <strong>Total Value to Pay: </strong><span>${(SumProperty(payAccounts, "value") - SumProperty(payAccounts, "paidValue") - SumProperty(payAccounts, "discount")).toFixed(2)}</span>
                            </div>

                            <Input.Date name="date" id="date" label="Date" defaultValue={today} onChange={handleChange} />

                        </> : <>
                            <strong className="text-danger">Please select one account to pay</strong>
                        </>}

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving || ! (payAccounts.length > 0) ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}