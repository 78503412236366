const API_BASE_URL_DEVELOPMENT = 'https://localhost:7044/api';
const API_BASE_URL_PRODUCTION = 'https://financialapi-murilo.azurewebsites.net/api';
const TOKEN = "userToken";

const ACCOUNTS_ENDPOINTS = {
    GET_ALL_ACCOUNTS: 'accounts',
    GET_ACCOUNT_BY_ID: 'accounts',
    PAY_ACCOUNTS: 'accounts/pay',
    DELETE_ACCOUNT: 'accounts',
    CANCEL_ACCOUNTS: 'accounts/cancel'
};

const ACCOUNT_ITEMS_ENDPOINTS = {
    GET_ALL_ACCOUNT_ITEMS: 'accountitems',
    GET_ACCOUNT_ITEM_BY_ID: 'accountitems',
    CREATE_ACCOUNT_ITEM: 'accountitems',
    DELETE_ACCOUNT_ITEM: 'accountitems'
}

const CATEGORIES_ENDPOINTS = {
    GET_ALL_CATEGORIES: 'categories',
    GET_CATEGORY_BY_ID: 'categories',
    CREATE_CATEGORY: 'categories',
    UPDATE_CATEGORY: 'categories',
    DELETE_CATEGORY: 'categories'
}

const PRODUCTS_ENDPOINTS = {
    GET_ALL_PRODUCTS: 'products',
    GET_PRODUCT_BY_ID: 'products',
    CREATE_PRODUCT: 'products',
    UPDATE_PRODUCT: 'products',
    DELETE_PRODUCT: 'products'
};

const PEOPLE_ENDPOINTS = {
    GET_ALL_PEOPLE: 'people',
    GET_PERSON_BY_ID: 'people',
    CREATE_PERSON: 'people',
    UPDATE_PERSON: 'people',
    DELETE_PERSON: 'people'
};

const PAYMENT_METHODS_ENDPOINTS = {
    GET_ALL_PAYMENT_METHODS: 'paymentmethods',
    GET_PAYMENT_METHOD_BY_ID: 'paymentmethods',
    CREATE_PAYMENT_METHOD: 'paymentmethods',
    UPDATE_PAYMENT_METHOD: 'paymentmethods',
    DELETE_PAYMENT_METHOD: 'paymentmethods'
}

const PAYMENTS_ENDPOINTS = {
    CREATE_PAYMENT: 'payments',
    DELETE_PAYMENT: 'payments'
}

const TRANSACTIONS_ENDPOINTS = {
    GET_ALL_TRANSACTIONS: 'transactions',
    GET_TRANSACTION_BY_ID: 'transactions',
    CREATE_TRANSACTION: 'transactions',
    UPDATE_TRANSACTION: 'transactions',
    DELETE_TRANSACTION: 'transactions'
}

const TRASACTION_ITEMS_ENDPOINTS = {
    GET_TRANSACTION_ITEM_BY_ID: 'transactionitems',
    CREATE_TRANSACTION_ITEM: 'transactionitems',
    UPDATE_TRANSACTION_ITEM: 'transactionitems',
    DELETE_TRANSACTION_ITEM: 'transactionitems'
}

const USERS_ENDPOINTS = {
    LOGIN: 'users/login',
    CREATE_USER: 'users',
    GET_ALL_USERS: 'users',
    GET_USER_BY_ID: 'users',
    UPDATE_USER: 'users',
    DELETE_USER: 'users',
    ACTIVATE_USER: 'users'
};

const Development = {
    APP_TOKEN: TOKEN,

    API_GET_ALL_ACCOUNTS: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNTS_ENDPOINTS.GET_ALL_ACCOUNTS}`,
    API_GET_ACCOUNT_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNTS_ENDPOINTS.GET_ACCOUNT_BY_ID}/`,
    API_PAY_ACCOUNTS: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNTS_ENDPOINTS.PAY_ACCOUNTS}`,
    API_DELETE_ACCOUNT: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNTS_ENDPOINTS.DELETE_ACCOUNT}/`,
    API_CANCEL_ACCOUNTS: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNTS_ENDPOINTS.CANCEL_ACCOUNTS}`,

    API_GET_ALL_ACCOUNT_ITEMS: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNT_ITEMS_ENDPOINTS.GET_ALL_ACCOUNT_ITEMS}`,
    API_GET_ACCOUNT_ITEM_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNT_ITEMS_ENDPOINTS.GET_ACCOUNT_ITEM_BY_ID}/`,
    API_CREATE_ACCOUNT_ITEM: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNT_ITEMS_ENDPOINTS.CREATE_ACCOUNT_ITEM}`,
    API_DELETE_ACCOUNT_ITEM: `${API_BASE_URL_DEVELOPMENT}/${ACCOUNT_ITEMS_ENDPOINTS.DELETE_ACCOUNT_ITEM}/`,

    API_GET_ALL_CATEGORIES: `${API_BASE_URL_DEVELOPMENT}/${CATEGORIES_ENDPOINTS.GET_ALL_CATEGORIES}`,
    API_GET_CATEGORY_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${CATEGORIES_ENDPOINTS.GET_CATEGORY_BY_ID}/`,
    API_CREATE_CATEGORY: `${API_BASE_URL_DEVELOPMENT}/${CATEGORIES_ENDPOINTS.CREATE_CATEGORY}`,
    API_UPDATE_CATEGORY: `${API_BASE_URL_DEVELOPMENT}/${CATEGORIES_ENDPOINTS.UPDATE_CATEGORY}/`,
    API_DELETE_CATEGORY: `${API_BASE_URL_DEVELOPMENT}/${CATEGORIES_ENDPOINTS.DELETE_CATEGORY}/`,

    API_GET_ALL_PRODUCTS: `${API_BASE_URL_DEVELOPMENT}/${PRODUCTS_ENDPOINTS.GET_ALL_PRODUCTS}`,
    API_GET_PRODUCT_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${PRODUCTS_ENDPOINTS.GET_PRODUCT_BY_ID}/`,
    API_CREATE_PRODUCT: `${API_BASE_URL_DEVELOPMENT}/${PRODUCTS_ENDPOINTS.CREATE_PRODUCT}`,
    API_UPDATE_PRODUCT: `${API_BASE_URL_DEVELOPMENT}/${PRODUCTS_ENDPOINTS.UPDATE_PRODUCT}/`,
    API_DELETE_PRODUCT: `${API_BASE_URL_DEVELOPMENT}/${PRODUCTS_ENDPOINTS.DELETE_PRODUCT}/`,

    API_GET_ALL_PEOPLE: `${API_BASE_URL_DEVELOPMENT}/${PEOPLE_ENDPOINTS.GET_ALL_PEOPLE}`,
    API_GET_PERSON_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${PEOPLE_ENDPOINTS.GET_PERSON_BY_ID}/`,
    API_CREATE_PERSON: `${API_BASE_URL_DEVELOPMENT}/${PEOPLE_ENDPOINTS.CREATE_PERSON}`,
    API_UPDATE_PERSON: `${API_BASE_URL_DEVELOPMENT}/${PEOPLE_ENDPOINTS.UPDATE_PERSON}/`,
    API_DELETE_PERSON: `${API_BASE_URL_DEVELOPMENT}/${PEOPLE_ENDPOINTS.DELETE_PERSON}/`,

    API_GET_ALL_PAYMENT_METHODS: `${API_BASE_URL_DEVELOPMENT}/${PAYMENT_METHODS_ENDPOINTS.GET_ALL_PAYMENT_METHODS}`,
    API_GET_PAYMENT_METHOD_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${PAYMENT_METHODS_ENDPOINTS.GET_PAYMENT_METHOD_BY_ID}/`,
    API_CREATE_PAYMENT_METHOD: `${API_BASE_URL_DEVELOPMENT}/${PAYMENT_METHODS_ENDPOINTS.CREATE_PAYMENT_METHOD}`,
    API_UPDATE_PAYMENT_METHOD: `${API_BASE_URL_DEVELOPMENT}/${PAYMENT_METHODS_ENDPOINTS.UPDATE_PAYMENT_METHOD}/`,
    API_DELETE_PAYMENT_METHOD: `${API_BASE_URL_DEVELOPMENT}/${PAYMENT_METHODS_ENDPOINTS.DELETE_PAYMENT_METHOD}/`,

    API_CREATE_PAYMENT: `${API_BASE_URL_DEVELOPMENT}/${PAYMENTS_ENDPOINTS.CREATE_PAYMENT}`,
    API_DELETE_PAYMENT: `${API_BASE_URL_DEVELOPMENT}/${PAYMENTS_ENDPOINTS.DELETE_PAYMENT}/`,

    API_GET_ALL_TRANSACTIONS: `${API_BASE_URL_DEVELOPMENT}/${TRANSACTIONS_ENDPOINTS.GET_ALL_TRANSACTIONS}`,
    API_GET_TRANSACTION_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${TRANSACTIONS_ENDPOINTS.GET_TRANSACTION_BY_ID}/`,
    API_CREATE_TRANSACTION: `${API_BASE_URL_DEVELOPMENT}/${TRANSACTIONS_ENDPOINTS.CREATE_TRANSACTION}`,
    API_UPDATE_TRANSACTION: `${API_BASE_URL_DEVELOPMENT}/${TRANSACTIONS_ENDPOINTS.UPDATE_TRANSACTION}/`,
    API_DELETE_TRANSACTION: `${API_BASE_URL_DEVELOPMENT}/${TRANSACTIONS_ENDPOINTS.DELETE_TRANSACTION}/`,

    API_GET_TRANSACTION_ITEM_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${TRASACTION_ITEMS_ENDPOINTS.GET_TRANSACTION_ITEM_BY_ID}/`,
    API_CREATE_TRANSACTION_ITEM: `${API_BASE_URL_DEVELOPMENT}/${TRASACTION_ITEMS_ENDPOINTS.CREATE_TRANSACTION_ITEM}`,
    API_UPDATE_TRANSACTION_ITEM: `${API_BASE_URL_DEVELOPMENT}/${TRASACTION_ITEMS_ENDPOINTS.UPDATE_TRANSACTION_ITEM}/`,
    API_DELETE_TRANSACTION_ITEM: `${API_BASE_URL_DEVELOPMENT}/${TRASACTION_ITEMS_ENDPOINTS.DELETE_TRANSACTION_ITEM}/`,

    API_USER_LOGIN: `${API_BASE_URL_DEVELOPMENT}/${USERS_ENDPOINTS.LOGIN}`,
    API_CREATE_USER: `${API_BASE_URL_DEVELOPMENT}/${USERS_ENDPOINTS.CREATE_USER}`,
    API_GET_ALL_USERS: `${API_BASE_URL_DEVELOPMENT}/${USERS_ENDPOINTS.GET_ALL_USERS}`,
    API_GET_USER_BY_ID: `${API_BASE_URL_DEVELOPMENT}/${USERS_ENDPOINTS.GET_USER_BY_ID}/`,
    API_UPDATE_USER: `${API_BASE_URL_DEVELOPMENT}/${USERS_ENDPOINTS.UPDATE_USER}/`,
    API_DELETE_USER: `${API_BASE_URL_DEVELOPMENT}/${USERS_ENDPOINTS.DELETE_USER}/`,
    API_ACTIVATE_USER: `${API_BASE_URL_DEVELOPMENT}/${USERS_ENDPOINTS.ACTIVATE_USER}/`,
};

const Production = {
    APP_TOKEN: TOKEN,

    API_GET_ALL_ACCOUNTS: `${API_BASE_URL_PRODUCTION}/${ACCOUNTS_ENDPOINTS.GET_ALL_ACCOUNTS}`,
    API_GET_ACCOUNT_BY_ID: `${API_BASE_URL_PRODUCTION}/${ACCOUNTS_ENDPOINTS.GET_ACCOUNT_BY_ID}/`,
    API_PAY_ACCOUNTS: `${API_BASE_URL_PRODUCTION}/${ACCOUNTS_ENDPOINTS.PAY_ACCOUNTS}`,
    API_DELETE_ACCOUNT: `${API_BASE_URL_PRODUCTION}/${ACCOUNTS_ENDPOINTS.DELETE_ACCOUNT}/`,
    API_CANCEL_ACCOUNTS: `${API_BASE_URL_PRODUCTION}/${ACCOUNTS_ENDPOINTS.CANCEL_ACCOUNTS}`,

    API_GET_ALL_ACCOUNT_ITEMS: `${API_BASE_URL_PRODUCTION}/${ACCOUNT_ITEMS_ENDPOINTS.GET_ALL_ACCOUNT_ITEMS}`,
    API_GET_ACCOUNT_ITEM_BY_ID: `${API_BASE_URL_PRODUCTION}/${ACCOUNT_ITEMS_ENDPOINTS.GET_ACCOUNT_ITEM_BY_ID}/`,
    API_CREATE_ACCOUNT_ITEM: `${API_BASE_URL_PRODUCTION}/${ACCOUNT_ITEMS_ENDPOINTS.CREATE_ACCOUNT_ITEM}`,
    API_DELETE_ACCOUNT_ITEM: `${API_BASE_URL_PRODUCTION}/${ACCOUNT_ITEMS_ENDPOINTS.DELETE_ACCOUNT_ITEM}/`,

    API_GET_ALL_CATEGORIES: `${API_BASE_URL_PRODUCTION}/${CATEGORIES_ENDPOINTS.GET_ALL_CATEGORIES}`,
    API_GET_CATEGORY_BY_ID: `${API_BASE_URL_PRODUCTION}/${CATEGORIES_ENDPOINTS.GET_CATEGORY_BY_ID}/`,
    API_CREATE_CATEGORY: `${API_BASE_URL_PRODUCTION}/${CATEGORIES_ENDPOINTS.CREATE_CATEGORY}`,
    API_UPDATE_CATEGORY: `${API_BASE_URL_PRODUCTION}/${CATEGORIES_ENDPOINTS.UPDATE_CATEGORY}/`,
    API_DELETE_CATEGORY: `${API_BASE_URL_PRODUCTION}/${CATEGORIES_ENDPOINTS.DELETE_CATEGORY}/`,

    API_GET_ALL_PRODUCTS: `${API_BASE_URL_PRODUCTION}/${PRODUCTS_ENDPOINTS.GET_ALL_PRODUCTS}`,
    API_GET_PRODUCT_BY_ID: `${API_BASE_URL_PRODUCTION}/${PRODUCTS_ENDPOINTS.GET_PRODUCT_BY_ID}/`,
    API_CREATE_PRODUCT: `${API_BASE_URL_PRODUCTION}/${PRODUCTS_ENDPOINTS.CREATE_PRODUCT}`,
    API_UPDATE_PRODUCT: `${API_BASE_URL_PRODUCTION}/${PRODUCTS_ENDPOINTS.UPDATE_PRODUCT}/`,
    API_DELETE_PRODUCT: `${API_BASE_URL_PRODUCTION}/${PRODUCTS_ENDPOINTS.DELETE_PRODUCT}/`,

    API_GET_ALL_PEOPLE: `${API_BASE_URL_PRODUCTION}/${PEOPLE_ENDPOINTS.GET_ALL_PEOPLE}`,
    API_GET_PERSON_BY_ID: `${API_BASE_URL_PRODUCTION}/${PEOPLE_ENDPOINTS.GET_PERSON_BY_ID}/`,
    API_CREATE_PERSON: `${API_BASE_URL_PRODUCTION}/${PEOPLE_ENDPOINTS.CREATE_PERSON}`,
    API_UPDATE_PERSON: `${API_BASE_URL_PRODUCTION}/${PEOPLE_ENDPOINTS.UPDATE_PERSON}/`,
    API_DELETE_PERSON: `${API_BASE_URL_PRODUCTION}/${PEOPLE_ENDPOINTS.DELETE_PERSON}/`,

    API_GET_ALL_PAYMENT_METHODS: `${API_BASE_URL_PRODUCTION}/${PAYMENT_METHODS_ENDPOINTS.GET_ALL_PAYMENT_METHODS}`,
    API_GET_PAYMENT_METHOD_BY_ID: `${API_BASE_URL_PRODUCTION}/${PAYMENT_METHODS_ENDPOINTS.GET_PAYMENT_METHOD_BY_ID}/`,
    API_CREATE_PAYMENT_METHOD: `${API_BASE_URL_PRODUCTION}/${PAYMENT_METHODS_ENDPOINTS.CREATE_PAYMENT_METHOD}`,
    API_UPDATE_PAYMENT_METHOD: `${API_BASE_URL_PRODUCTION}/${PAYMENT_METHODS_ENDPOINTS.UPDATE_PAYMENT_METHOD}/`,
    API_DELETE_PAYMENT_METHOD: `${API_BASE_URL_PRODUCTION}/${PAYMENT_METHODS_ENDPOINTS.DELETE_PAYMENT_METHOD}/`,

    API_CREATE_PAYMENT: `${API_BASE_URL_PRODUCTION}/${PAYMENTS_ENDPOINTS.CREATE_PAYMENT}`,
    API_DELETE_PAYMENT: `${API_BASE_URL_PRODUCTION}/${PAYMENTS_ENDPOINTS.DELETE_PAYMENT}/`,

    API_GET_ALL_TRANSACTIONS: `${API_BASE_URL_PRODUCTION}/${TRANSACTIONS_ENDPOINTS.GET_ALL_TRANSACTIONS}`,
    API_GET_TRANSACTION_BY_ID: `${API_BASE_URL_PRODUCTION}/${TRANSACTIONS_ENDPOINTS.GET_TRANSACTION_BY_ID}/`,
    API_CREATE_TRANSACTION: `${API_BASE_URL_PRODUCTION}/${TRANSACTIONS_ENDPOINTS.CREATE_TRANSACTION}`,
    API_UPDATE_TRANSACTION: `${API_BASE_URL_PRODUCTION}/${TRANSACTIONS_ENDPOINTS.UPDATE_TRANSACTION}/`,
    API_DELETE_TRANSACTION: `${API_BASE_URL_PRODUCTION}/${TRANSACTIONS_ENDPOINTS.DELETE_TRANSACTION}/`,

    API_GET_TRANSACTION_ITEM_BY_ID: `${API_BASE_URL_PRODUCTION}/${TRASACTION_ITEMS_ENDPOINTS.GET_TRANSACTION_ITEM_BY_ID}/`,
    API_CREATE_TRANSACTION_ITEM: `${API_BASE_URL_PRODUCTION}/${TRASACTION_ITEMS_ENDPOINTS.CREATE_TRANSACTION_ITEM}`,
    API_UPDATE_TRANSACTION_ITEM: `${API_BASE_URL_PRODUCTION}/${TRASACTION_ITEMS_ENDPOINTS.UPDATE_TRANSACTION_ITEM}/`,
    API_DELETE_TRANSACTION_ITEM: `${API_BASE_URL_PRODUCTION}/${TRASACTION_ITEMS_ENDPOINTS.DELETE_TRANSACTION_ITEM}/`,

    API_USER_LOGIN: `${API_BASE_URL_PRODUCTION}/${USERS_ENDPOINTS.LOGIN}`,
    API_CREATE_USER: `${API_BASE_URL_PRODUCTION}/${USERS_ENDPOINTS.CREATE_USER}`,
    API_GET_ALL_USERS: `${API_BASE_URL_PRODUCTION}/${USERS_ENDPOINTS.GET_ALL_USERS}`,
    API_GET_USER_BY_ID: `${API_BASE_URL_PRODUCTION}/${USERS_ENDPOINTS.GET_USER_BY_ID}/`,
    API_UPDATE_USER: `${API_BASE_URL_PRODUCTION}/${USERS_ENDPOINTS.UPDATE_USER}/`,
    API_DELETE_USER: `${API_BASE_URL_PRODUCTION}/${USERS_ENDPOINTS.DELETE_USER}/`,
    API_ACTIVATE_USER: `${API_BASE_URL_PRODUCTION}/${USERS_ENDPOINTS.ACTIVATE_USER}/`,
};

const Constants = process.env.NODE_ENV === 'development' ? Development : Production;

export default Constants;