import React, { useState } from "react";
import Button from "../../../Button";
import EditTransactionItem from "../EditTransactionItem";
import { Delete } from "../../../../utilities/TransactionItemsService";

export default function Body(props) {

    const [transactionItem, setTransactionItem] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);

    function handleShowEditModal(transactionItem) {
        setTransactionItem(transactionItem);
        setShowEditModal(true);
    }
    function handleCloseEditModal() {
        setShowEditModal(false);
    }

    function DeleteItem(id) {
        Delete(id).then(() => {
            props.handleDeleted();
            props.closeModal();
        }).catch(error => {
            console.log(error);
            props.setErrorMessage(error.response.data);
        });
    }

    return (
        <tbody>
            {showEditModal ? <EditTransactionItem closeModal={handleCloseEditModal} handleUpdated={props.handleUpdated} transactionItem={transactionItem} /> : <></>}
            {props.transactionItems.sort((a, b) => a.product.title.localeCompare(b.product.title)).map((transactionItem) => (
                <tr key={transactionItem.id}>
                    <td className="text-center">{transactionItem.id}</td>
                    <td>{transactionItem.product.title}</td>
                    <td className="text-center">{transactionItem.quantity}</td>
                    <td className="text-center">${transactionItem.unitPrice.toFixed(2)}</td>
                    <td className="text-center">${(transactionItem.quantity * transactionItem.unitPrice).toFixed(2)}</td>
                    <td className="d-flex justify-content-between">
                        <Button.Secondary type="button" text="Edit" onClick={() => handleShowEditModal(transactionItem)} />
                        <Button.Danger type="button" text="Delete" onClick={() => DeleteItem(transactionItem.id)} />
                    </td>
                </tr>
            ))}
        </tbody>
    );
}