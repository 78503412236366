import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

export default function Body(props) {

    return (
        <>
            <tbody>
                {props.transactions.map((transaction) => (
                    <tr key={transaction.id}>
                        <td className="text-center">{transaction.id}</td>
                        <td className="text-center">{new Date(transaction.date).toLocaleDateString()}</td>
                        <td>{transaction.person.name}</td>
                        <td className="text-center">${transaction.totalValue.toFixed(2)}</td>
                        <td className="d-flex justify-content-between">
                            <Link className='btn btn-primary btn-sm w-100' to={`/${props.type.toLowerCase()}-details/${transaction.id}`}>
                                Details
                            </Link>
                            <Button.Danger type="button" text="Delete" onClick={() => props.onDelete(transaction.id)} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    );
}