import React, { useState, useEffect } from "react";
import Accounts from "../Accounts";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Get } from "../../utilities/AccountsService";
import { Get as GetPaymentMethods } from "../../utilities/PaymentMethodsService";
import { Get as GetCategories } from "../../utilities/CategoriesService";
import { AccountType } from "../../utilities/Enums/AccountTypeEnum";
import { FormatDate } from "../../utilities/Methods";
import Loading from "../Loading";
import ErrorMessage from "../ErrorMessage";
import SuccessMessage from "../SuccessMessage";
import Button from "../Button";
import Chart from "./chart";
import Progression from "./progression";

export default function Financial() {

    const today = new Date();
    const [month, setMonth] = useState(today.getMonth() + 1);
    const [year, setYear] = useState(today.getFullYear());

    const initialFormatedDate = FormatDate(new Date(today.getFullYear(), today.getMonth(), 1));
    const finalFormatedDate = FormatDate(new Date(today.getFullYear(), today.getMonth(), new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()));

    const [accountsPayable, setAccountsPayable] = useState({});
    const [accountsReceivable, setAccountsReceivable] = useState({});
    const [filteredAccountsPayable, setFilteredAccountsPayable] = useState({});
    const [filteredAccountsReceivable, setFilteredAccountsReceivable] = useState({});
    const [paymentMethods, setPaymentMethods] = useState({});
    const [paymentMethodSelected, setPaymentMethodSelected] = useState(0);
    const [categories, setCategories] = useState({});
    const [categorySelected, setCategorySelected] = useState(0);
    const [initialDate, setInitialDate] = useState(initialFormatedDate);
    const [finalDate, setFinalDate] = useState(finalFormatedDate);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [updates, setUpdates] = useState(0);

    useEffect(() => {
        setLoading(true);
        function getData() {
            Get(initialDate, finalDate, AccountType.Payable).then(res => {
                setAccountsPayable(res);
                setFilteredAccountsPayable(res);
                Get(initialDate, finalDate, AccountType.Receivable).then(res => {
                    setAccountsReceivable(res);
                    setFilteredAccountsReceivable(res);
                    GetPaymentMethods().then(res => {
                        setPaymentMethods(res.sort((a, b) => a.title.localeCompare(b.title)));
                        GetCategories().then(res => {
                            setCategories(res.sort((a,b) => a.title.localeCompare(b.title)));
                            setErrorMessage('');
                            setLoading(false);
                        }).catch(error => {
                            console.log(error);
                            setErrorMessage('Error at fetching categories from API');
                            setLoading(false);
                        });
                    }).catch(error => {
                        console.log(error);
                        setErrorMessage('Error at fetching paymentMethods from API');
                        setLoading(false);
                    });
                }).catch(error => {
                    console.log(error);
                    setErrorMessage('Error at fetching accounts from API');
                    setLoading(false);
                });
            }).catch(error => {
                console.log(error);
                setErrorMessage('Error at fetching accounts from API');
                setLoading(false);
            });
        };
        getData();
    }, [updates, initialDate, finalDate]);


    function handleMonth(e) {
        setMonth(e.target.value);
    }
    function handleYear(e) {
        setYear(e.target.value);
    }

    function handleSearch() {
        let initial = FormatDate(new Date(year, month - 1, 1));
        let final = FormatDate(new Date(year, month, 0));
        setInitialDate(initial);
        setFinalDate(final);
    }

    function handlePaid() {
        setSuccessMessage("Successfuly paid account");
        setErrorMessage('');
        setUpdates(updates + 1);
    }
    function handleCancelled() {
        setSuccessMessage("Successfuly cancelled account");
        setErrorMessage('');
        setUpdates(updates + 1);
    }

    function handleChangePaymentMethod(e) {
        setPaymentMethodSelected(parseInt(e.target.value));
        filterAccounts(parseInt(e.target.value), categorySelected);
    }
    function handleChangeCategory(e){
        setCategorySelected(parseInt(e.target.value));
        filterAccounts(paymentMethodSelected, parseInt(e.target.value));
    }

    function filterAccounts(paymentMethod, category){
        if (paymentMethod === 0 && category === 0){
            setFilteredAccountsPayable(accountsPayable);
            setFilteredAccountsReceivable(accountsReceivable);
        } else if (paymentMethod !== 0 && category === 0){
            setFilteredAccountsPayable(accountsPayable.filter((item) => item.payment.paymentMethodId === paymentMethod));
            setFilteredAccountsReceivable(accountsReceivable.filter((item) => item.payment.paymentMethodId === paymentMethod));
        } else if (paymentMethod === 0 && category !== 0){
            setFilteredAccountsPayable(accountsPayable.filter((item) => item.payment.transaction.items.find(i => i.product.categoryId === category)));
            setFilteredAccountsReceivable(accountsReceivable.filter((item) => item.payment.transaction.items.find(i => i.product.categoryId === category)));
        } else {
            setFilteredAccountsPayable(accountsPayable.filter((item) => item.payment.paymentMethodId === paymentMethod && item.payment.transaction.items.find(i => i.product.categoryId === category)));
            setFilteredAccountsReceivable(accountsReceivable.filter((item) => item.payment.paymentMethodId === paymentMethod && item.payment.transaction.items.find(i => i.product.categoryId === category)));
        }
    }

    return (
        loading ? <Loading text="Loading..." />
            : <main className="container">
                {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></>}
                {successMessage ? <SuccessMessage msg={successMessage} /> : <></>}
                <div className="row align-items-center mt-3">
                    <div className="col-md-3">
                        <div className="d-flex align-items-center form-group mb-3">
                            <label htmlFor="year" className="form-label m-1">Year:</label>
                            <input type="number" name="year" id="year" defaultValue={year} className="form-control form-control-sm m-1" onChange={handleYear} />
                            <label htmlFor="month" className="form-label m-1">Month:</label>
                            <input type="number" name="month" id="month" min="1" max="12" defaultValue={month} className="form-control form-control-sm m-1" onChange={handleMonth} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-center form-group mb-3">
                            <Button.Primary type="button" text="Search" disabled={false} onClick={handleSearch} />
                        </div>
                    </div>
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                        <div className="form-group mb-3">
                            <select className="form-select form-select-sm" name="paymentMethodId" id="paymentMethodId" onChange={handleChangePaymentMethod}>
                                <option value="0">Select Payment Method...</option>
                                {paymentMethods.map((paymentMethod) => (
                                    <option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.title}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb-3">
                            <select className="form-select form-select-sm" name="categoryId" id="categoryId" onChange={handleChangeCategory}>
                                <option value="0">Select Category...</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>{category.title}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="payable" className="mt-4">
                    <Tab eventKey="payable" title="Payable">
                        {filteredAccountsPayable && filteredAccountsPayable.length > 0 ?
                            <div className="d-flex">
                                <div className="col-md-6">
                                    <Chart accounts={filteredAccountsPayable} />
                                </div>
                                <div className="col-md-6">
                                    <Progression accounts={filteredAccountsPayable} />
                                </div>
                            </div> : ""}
                        <Accounts accounts={filteredAccountsPayable} setErrorMessage={setErrorMessage} handlePaid={handlePaid} handleCancelled={handleCancelled} />
                    </Tab>
                    <Tab eventKey="receivable" title="Receivable">
                        {filteredAccountsReceivable && filteredAccountsReceivable.length > 0 ?
                            <div className="d-flex">
                                <div className="col-md-6">
                                    <Chart accounts={filteredAccountsReceivable} />
                                </div>
                                <div className="col-md-6">
                                    <Progression accounts={filteredAccountsReceivable} />
                                </div>
                            </div> : ""}
                        <Accounts accounts={filteredAccountsReceivable} setErrorMessage={setErrorMessage} handlePaid={handlePaid} handleCancelled={handleCancelled} />
                    </Tab>
                </Tabs>
            </main>
    );
}