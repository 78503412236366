export const PersonType = {
    Supplier: 1,
    Customer: 2,
    Both: 3
};

export const GetType = (type) => {
    let result = "";
    switch (type) {
        case PersonType.Supplier:
            result = "Supplier";
            break;
        case PersonType.Customer:
            result = "Customer";
            break;
        case PersonType.Both:
            result = "Customer and Supplier";
            break;
        default:
            result = "";
            break;
    }
    return result;
};