import React, {useState} from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Post } from "../../../utilities/PaymentMethodsService";
import Input from "../../Input";
import Button from '../../Button';

export default function NewPaymentMethod(props){

    const emptyObject = Object.freeze({
        title: '',
    });

    const [paymentMethod, setPaymentMethod] = useState(emptyObject);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function postNewObject() {
        setSaving(true);
        Post(paymentMethod).then(() => {
            props.createdNewObject(true);
            props.closeModal();
        }).catch(error => {
            if (error.response.status === 400) {
                setErrorMessage(JSON.stringify(error.response.data));
            }
            setSaving(false);
        });
    }

    function handleCreateForm(e) {
        e.preventDefault();
        postNewObject();
    }

    const handleChange = (e) => {
        setPaymentMethod({
            ...paymentMethod,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleCreateForm}>
                <Modal.Header>
                    <Modal.Title>New Payment Method</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Input.Text name="title" id="title" label="Title" onChange={handleChange} />

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}