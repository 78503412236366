export const AccountType = {
    Payable: 1,
    Receivable: 2
}

export const GetType = (type) => {
    let text = '';
    switch (type) {
        case AccountType.Payable:
            text = "Payable";
            break;
        case AccountType.Receivable:
            text = "Receivable";
            break;
        default:
            text = "";
            break;
    }
    return text;
}