import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Put } from "../../../utilities/PeopleService";
import Input from "../../Input";
import Button from "../../Button";

export default function EditPerson(props) {

    const [person, setPerson] = useState(props.person);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function updatePerson() {
        setSaving(true);
        Put(person.id, person).then(() => {
            props.updatedPerson();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        updatePerson();
    }

    const handleChange = (e) => {
        if (e.target.name === "type") {
            setPerson({
                ...person,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setPerson({
                ...person,
                [e.target.name]: e.target.value,
            });
        }
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>Edit Person</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Input.Text name="name" id="name" label="Name" defaultValue={person.name} onChange={handleChange} />
                    <Input.Text name="cpf" id="cpf" label="CPF" defaultValue={person.cpf} onChange={handleChange} />
                    <Input.Text name="cnpj" id="cnpj" label="CNPJ" defaultValue={person.cnpj} onChange={handleChange} />
                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <Input.Radio name="type" id="customerType" text="Customer" value={1} onChange={handleChange} checked={person.type === 1} />
                        <Input.Radio name="type" id="supplierType" text="Supplier" value={2} onChange={handleChange} checked={person.type === 2} />
                        <Input.Radio name="type" id="bothType" text="Both" value={3} onChange={handleChange} checked={person.type === 3} />
                    </div>

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}