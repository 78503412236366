import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { GetType } from "../../../utilities/Enums/AccountTypeEnum";
import { AccountStatus, GetStatus } from "../../../utilities/Enums/AccountStatusEnum";
import { AccountItemStatus, GetItemStatus } from "../../../utilities/Enums/AccountItemStatusEnum";
import { GetType as GetTransactionType, GetPersonTypeRequired } from "../../../utilities/Enums/TransactionTypeEnum";

export default function Details(props) {
    return (
        <>
            {props.account.payment ?
                <>
                    {props.account.payment.transaction ?
                        <Accordion className="mb-4">
                            <Accordion.Item eventKey="details">
                                <Accordion.Header>Transaction Details</Accordion.Header>
                                <Accordion.Body>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div className="col-md-6">
                                            <strong>{GetTransactionType(props.account.payment.transaction.type)} Date: </strong><span>{new Date(props.account.payment.transaction.date).toLocaleDateString()}</span>
                                            <br />
                                            <strong>{GetPersonTypeRequired(props.account.payment.transaction.type)}: </strong><span>{props.account.payment.transaction.person.name}</span>
                                            <br />
                                            <Link className='btn btn-primary btn-sm w-50' to={`/${GetTransactionType(props.account.payment.transaction.type).toLowerCase()}-details/${props.account.payment.transaction.id}`}>
                                                Details
                                            </Link>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <strong>Items: </strong><span>${props.account.payment.transaction.totalItems.toFixed(2)}</span>
                                            <br />
                                            <strong>Shipping Cost: </strong><span>${props.account.payment.transaction.shippingCost.toFixed(2)}</span>
                                            <br />
                                            <strong>Discount: </strong><span>${props.account.payment.transaction.discount.toFixed(2)}</span>
                                            <br />
                                            <strong>Total: </strong><span>${props.account.payment.transaction.totalValue.toFixed(2)}</span>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> : <></>}
                    <strong>Payment Method: </strong><span>{props.account.payment.paymentMethod.title}</span>
                </> : ""}
            <div className="d-flex justify-content-between">
                <div className="col-md-6">
                    <strong>Type: </strong><span>{GetType(props.account.type)}</span>
                    <br />
                    <strong>Due Date: </strong><span>{new Date(props.account.dueDate).toLocaleDateString()}</span>
                    <br />
                    <strong>Value: </strong><span>${props.account.value.toFixed(2)}</span>
                    <br />
                    <strong>Status: </strong><span>{GetStatus(props.account.status)}</span>
                </div>
                <div className="col-md-6 text-end">
                    <strong>Paid Value: </strong><span>${props.account.paidValue.toFixed(2)}</span>
                    <br />
                    <strong>Discount: </strong><span>${props.account.discount.toFixed(2)}</span>
                    <br />
                    <strong>Remaining: </strong><span>${(props.account.value - props.account.paidValue - props.account.discount).toFixed(2)}</span>
                </div>
            </div>

            {props.account.status === AccountStatus.Partial || props.account.status === AccountStatus.Paid ?
                <div className="mt-2">
                    {props.account.items.map((accountItem) => (
                        <div key={accountItem.id} className="card border-dark mt-1">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div className="col-md-6">
                                        <strong>Date: </strong><span>{new Date(accountItem.date).toLocaleDateString()}</span>
                                        <br />
                                        <strong>Status: </strong><span>{GetItemStatus(accountItem.status)}</span>
                                        {accountItem.status === AccountItemStatus.Cancelled ? <>
                                            <br />
                                            <strong>Cancelled at </strong><span>{new Date(accountItem.cancelledDate).toLocaleDateString()}</span>
                                        </> : <></>}
                                    </div>
                                    <div className="col-md-6">
                                        <strong>Paid Value: </strong><span>${accountItem.paidValue.toFixed(2)} </span>
                                        <br />
                                        <strong>Discount: </strong><span>${accountItem.discount.toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> : <></>}
        </>
    );
}