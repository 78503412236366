import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetById } from "../../../utilities/PeopleService";
import { GetType } from "../../../utilities/Enums/PersonTypeEnum";
import Loading from "../../Loading";
import SuccessMessage from "../../SuccessMessage";
import ErrorMessage from "../../ErrorMessage";
import EditPerson from "../EditPerson";
import Transactions from "./transactions";
import { TransactionType } from "../../../utilities/Enums/TransactionTypeEnum";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

export default function PersonDetails() {

    const { id } = useParams();

    const [person, setPerson] = useState({});
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [updates, setUpdates] = useState(0);
    const [purchases, setPurchases] = useState({});
    const [sales, setSales] = useState({});

    useEffect(() => {
        setLoading(true);
        function GetData() {
            GetById(id).then(res => {
                setPerson(res);
                if (res.transactions && res.transactions.length > 0) {
                    setPurchases(res.transactions.filter((item) => item.type === TransactionType.Purchase));
                    setSales(res.transactions.filter((item) => item.type === TransactionType.Sale));
                }
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage("Error at fetching data from API");
                setLoading(false);
            });
        };
        GetData();
    }, [id, updates]);

    function handleShowEditModal() {
        setShowEditModal(true);
    }
    function handleCloseEditModal() {
        setShowEditModal(false);
    }

    function handleUpdated() {
        setSuccessMessage("Successfuly updated");
        setErrorMessage('');
        setUpdates(updates + 1);
    }

    return (
        loading ? <Loading text="Loading..." /> :
            <main className="container">
                {successMessage ? <SuccessMessage msg={successMessage} /> : ""}
                {errorMessage ? <ErrorMessage msg={errorMessage} /> : ""}

                {showEditModal ? <EditPerson closeModal={handleCloseEditModal} updatedPerson={handleUpdated} person={person} /> : <></>}

                {person ?
                    <>
                        <div className="card border-dark">
                            <div className="card-body">
                                <strong>Name: </strong><span>{person.name}</span>
                                <br />
                                <strong>CPF: </strong><span>{person.cpf}</span>
                                <br />
                                <strong>CNPJ: </strong><span>{person.cnpj}</span>
                                <br />
                                <strong>Type: </strong><span>{GetType(person.type)}</span>
                                <br />
                                <button type="button" className="btn btn-primary btn-sm col-md-3" onClick={handleShowEditModal}>Edit</button>

                                <Tabs defaultActiveKey="purchases" className="mt-4">
                                    <Tab eventKey="purchases" title="Purchases">
                                        {purchases.length > 0 ?
                                            <Transactions transactions={purchases} /> : ""}
                                    </Tab>
                                    <Tab eventKey="sales" title="Sales">
                                        {sales.length > 0 ?
                                            <Transactions transactions={sales} /> : ""}
                                    </Tab>
                                </Tabs>

                            </div>
                        </div>
                    </> : ""}
            </main>
    );
}