import React, {useState} from "react";
import Button from '../Button';
import EditCategory from './EditCategory';

export default function Body(props){

    const [showEditModal, setShowEditModal] = useState(false);
    const [category, setCategory] = useState({});

    function handleShowEditModal(category) {
        setCategory(category);
        setShowEditModal(true);
    }
    function handleCloseEditModal() {
        setShowEditModal(false);
    }

    return (
        <>
            {showEditModal ? <EditCategory closeModal={handleCloseEditModal} updatedObject={props.updatedObject} category={category} /> : <></>}
            <tbody>
                {props.categories.length > 0 ?
                    props.categories.sort((a, b) => a.title.localeCompare(b.title)).map((category) => (
                        <tr key={category.id}>
                            <td className="text-center">{category.id}</td>
                            <td>{category.title}</td>
                            <td className="d-flex justify-content-between">
                                <Button.Secondary type="button" text="Edit" onClick={() => handleShowEditModal(category)} />
                                <Button.Danger type="button" text="Delete" onClick={() => props.onDelete(category.id)} />
                            </td>
                        </tr>
                    )) : <strong className="text-danger">No categories found</strong>}
            </tbody>
        </>
    );
}