import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Post } from "../../../utilities/PaymentsService";
import { Get } from "../../../utilities/PaymentMethodsService";
import Input from "../../Input";
import Button from "../../Button";
import { FormatDate } from "../../../utilities/Methods";

export default function NewPayment(props) {

    const today = FormatDate(new Date());

    const emptyObject = Object.freeze({
        transactionId: props.transactionId,
        paymentMethodId: 0,
        dueDate: today,
        parcels: 0,
        value: 0
    });

    const [payment, setPayment] = useState(emptyObject);
    const [paymentMethods, setPaymentMethods] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        function getData() {
            try {
                Get().then(res => {
                    setPaymentMethods(res.sort((a, b) => a.title.localeCompare(b.title)));
                    setErrorMessage('');
                    setLoading(false);
                });
            } catch (error) {
                console.log(error);
                setErrorMessage('Error at fetching payment methods from API');
                setLoading(false);
            }
        };
        getData();
    }, []);

    function createObject() {
        setSaving(true);
        Post(payment).then(() => {
            props.createdObject();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage('Error at fetching data from the api.');
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        createObject();
    }

    const handleChange = (e) => {
        setPayment({
            ...payment,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>New Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {loading ? <Loading text='Loading...' /> : <>
                        <div className="form-group form-floating mb-3">
                            <select className="form-select" name="paymentMethodId" id="paymentMethodId" onChange={handleChange}>
                                <option value="0">Select...</option>
                                {paymentMethods.map((paymentMethod) => (
                                    <option value={paymentMethod.id} key={paymentMethod.id}>{paymentMethod.title}</option>
                                ))}
                            </select>
                            <label htmlFor="paymentMethodId" className="form-label">Payment Method</label>
                        </div>

                        <Input.Date name="dueDate" id="dueDate" label="Due Date" defaultValue={today} onChange={handleChange} />
                        <Input.Number name="parcels" id="parcels" label="Parcels" onChange={handleChange} />
                        <Input.Number name="value" id="value" label="Value" onChange={handleChange} />

                    </>}

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}