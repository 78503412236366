import React from "react";

export default function Header(props) {
    return (
        <thead>
            <tr>
                <th colSpan="2">Items Count: {props.count}</th>
                <th colSpan="4" className="text-end">Total Cost: ${props.totalValue.toFixed(2)}</th>
            </tr>
            <tr>
                <th className="text-center">Id</th>
                <th className="text-center">Product</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Unit Price</th>
                <th className="text-center">Total Price</th>
                <th></th>
            </tr>
        </thead>
    );
}