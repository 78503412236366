import Constants from "./Constants";
import { GetToken } from "./TokenService";
import axios from 'axios';

export const Get = async (initialDate, finalDate, type) => {
    const token = GetToken(Constants.APP_TOKEN);
    let response = {};
    try {
        response = await axios.get(Constants.API_GET_ALL_TRANSACTIONS, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                "initial": initialDate,
                "final": finalDate,
                "type": type
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const GetById = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    let response = {};
    try {
        response = await axios.get(Constants.API_GET_TRANSACTION_BY_ID + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const Post = async (transaction) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.post(Constants.API_CREATE_TRANSACTION, transaction, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Put = async (id, transaction) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.put(Constants.API_UPDATE_TRANSACTION + id, transaction, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Delete = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.delete(Constants.API_DELETE_TRANSACTION + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};