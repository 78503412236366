import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Cancel } from "../../../utilities/AccountsService";
import Loading from "../../Loading";
import Button from "../../Button";
import { FormatDate } from "../../../utilities/Methods";

export default function AccountCancelMultiple(props) {

    const cancelAccounts = props.accounts;

    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    function cancel() {
        setSaving(true);
        const cancelObject = {
            accountsId: cancelAccounts.map((item) => item.id),
            date: FormatDate(new Date())
        };
        Cancel(cancelObject).then(() => {
            props.handleCancelled();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        cancel();
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>Cancel Multiple Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {cancelAccounts.length > 0 ?
                        <>
                            <div className="form-group shadow mb-3">
                                <strong>Are you sure you want to cancel these accounts?</strong>
                                <br/>
                                <strong>Count: {cancelAccounts.length}</strong>
                            </div>
                        </> : <>
                            <strong className="text-danger">Please select one account to cancel</strong>
                        </>}

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Primary type="button" text="No" onClick={props.closeModal} />
                        <Button.Danger type="submit" text="Yes, Cancel" disabled={saving || ! (cancelAccounts.length > 0) ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}