import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Post } from "../../../utilities/AccountItemsService";
import Loading from "../../Loading";
import Input from "../../Input";
import Button from "../../Button";
import { FormatDate } from "../../../utilities/Methods";

export default function AccountPay(props) {

    const today = FormatDate(new Date());

    const emptyObject = Object.freeze({
        accountId: props.account.id,
        date: today,
        paidValue: 0,
        discount: 0
    });

    const [accountItem, setAccountItem] = useState(emptyObject);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function createObject() {
        setSaving(true);
        Post(accountItem).then(() => {
            props.handlePaid();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        createObject();
    }

    const handleChange = (e) => {
        setAccountItem({
            ...accountItem,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>Pay Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group shadow mb-3">
                        <strong>Remaining Value: </strong><span>${(props.account.value - props.account.paidValue - props.account.discount).toFixed(2)}</span>
                    </div>

                    <Input.Date name="date" id="date" label="Date" defaultValue={today} onChange={handleChange} />
                    <Input.Number name="discount" id="discount" label="Discount" onChange={handleChange} />
                    <Input.Number name="paidValue" id="paidValue" label="Paid Value" onChange={handleChange} />

                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}