export const AccountStatus = {
    Awaiting: 0,
    Partial: 1,
    Paid: 2,
    Cancelled: 3
}

export const GetStatus = (status) => {
    let text = '';
    switch (status) {
        case AccountStatus.Awaiting:
            text = "Awaiting";
            break;
        case AccountStatus.Partial:
            text = "Partial";
            break;
        case AccountStatus.Paid:
            text = "Paid";
            break;
        case AccountStatus.Cancelled:
            text = "Cancelled";
            break;
        default:
            text = "";
            break;
    }
    return text;
}