import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function Navigator(props) {
    let navigate = useNavigate();

    function logout() {
        props.setLogin(false);
        localStorage.clear();
        navigate('/');
    }
    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" className="navbar-dark bg-dark">
            <Container>
                <NavLink className="navbar-brand" to="/">Financial</NavLink>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {props.login ? <>
                        <Nav className="me-auto">
                            <NavLink className="nav-link" to="/products">Products</NavLink>
                            <NavLink className="nav-link" to="/people">Customers/Suppliers</NavLink>
                            <NavLink className="nav-link" to='/categories'>Categories</NavLink>
                            <NavLink className="nav-link" to="/paymentmethods">Payment Methods</NavLink>
                            <NavLink className="nav-link" to="/purchases">Purchases</NavLink>
                            <NavLink className="nav-link" to="/sales">Sales</NavLink>
                            <NavLink className="nav-link" to="/accounts">Accounts</NavLink>
                        </Nav>
                        <Nav>
                            <button type="button" className="btn-link bg-dark nav-link text-start" onClick={logout} >Logout</button>
                        </Nav>
                    </> : <>
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                            <NavLink className="nav-link" to="/login">Login</NavLink>
                        </Nav>
                    </>}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};