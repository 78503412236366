import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Loading from "../../Loading";
import Modal from 'react-bootstrap/Modal';
import { Get } from "../../../utilities/CategoriesService";
import { Put } from "../../../utilities/ProductsService";
import Input from "../../Input";
import Button from "../../Button";

export default function EditProduct(props) {

    const [product, setProduct] = useState(props.product);
    const [categories, setCategories] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        function GetData() {
            Get().then(res => {
                setCategories(res.sort((a, b) => a.title.localeCompare(b.title)));
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage(error.response.data);
                setLoading(false);
            });
        };
        GetData();
    }, []);

    function updateProduct() {
        setSaving(true);
        Put(product.id, product).then(() => {
            props.updatedProduct();
            props.closeModal();
        }).catch(function (error) {
            console.log(error);
            setErrorMessage(error.response.data);
            setSaving(false);
        });
    }

    function handleForm(e) {
        e.preventDefault();
        updateProduct();
    }

    const handleChange = (e) => {
        setProduct({
            ...product,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <Modal show={true} onHide={props.closeModal}>
            <Form onSubmit={handleForm}>
                <Modal.Header>
                    <Modal.Title>Edit Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loading text="Loading..." />
                        : categories && categories.length > 0 ?
                            <>
                                <Input.Text name="title" id="title" label="Title" defaultValue={product.title} onChange={handleChange} />
                                <div className="form-group form-floating mb-3">
                                    <select className="form-select" name="categoryId" id="categoryId" defaultValue={product.categoryId} onChange={handleChange}>
                                        <option value="0">Select...</option>
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.id}>{category.title}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="categoryId" className="form-label">Category</label>
                                </div>
                                <Input.Number name="price" id="price" label="Price" defaultValue={product.price} onChange={handleChange} />
                                <Input.TextArea name="description" id="description" label="Description" defaultValue={product.description} rows="3" onChange={handleChange} />
                            </> : <></>}
                    <div className="form-group shadow mb-3">
                        {saving ? <Loading text='Saving...' /> : <></>}
                        <span className="text-danger">{errorMessage}</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100">
                        <Button.Success type="button" text="Cancel" onClick={props.closeModal} />
                        <Button.Primary type="submit" text="Save" disabled={saving ? 'disabled' : ''} />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}