import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../Button";
import Details from "./details";

export default function AccountDetails(props){

    return (
        <Modal show={true} onHide={props.closeModal}>
            
                <Modal.Header>
                    <Modal.Title>Account Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Details account={props.account} />
                </Modal.Body>
                <Modal.Footer>
                    <Button.Secondary type="button" text="Close" onClick={props.closeModal} />
                </Modal.Footer>
            
        </Modal>
    );
}