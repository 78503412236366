import React, { useState } from "react";
import { SumValue } from "../../utilities/Methods";
import NewPayment from "./NewPayment";
import { Delete } from "../../utilities/PaymentsService";
import SuccessMessage from "../SuccessMessage";
import ErrorMessage from "../ErrorMessage";
import Button from "../Button";
import Accounts from "../Accounts";


export default function Payments(props){

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function handleShowCreateModal(){
        setShowCreateModal(true);
    }
    function handleCloseCreateModal(){
        setShowCreateModal(false);
    }
    function handleCreatedObject(){
        props.handleCreated();
    }
    function handleDelete(id){
        Delete(id).then(() => {
            setSuccessMessage("Successfuly deleted payment");
            setErrorMessage('');
            props.handleDeleted();
        }).catch(error => {
            console.log(error);
            setErrorMessage(error.response.data);
            setSuccessMessage('');
        });
    }

    return (
        <>
            {showCreateModal ? <NewPayment closeModal={handleCloseCreateModal} createdObject={handleCreatedObject} transactionId={props.transactionId} /> : <></>}
            {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></>}
            {successMessage ? <SuccessMessage msg={successMessage} /> : <></>}
            <br />
            <button type="button" className="btn btn-primary btn-sm col-md-3 mt-2" onClick={handleShowCreateModal}>New Payment</button>
            
            <div className="text-light">
                <div className="d-flex justify-content-between mt-4">
                    <strong>Payments Count: {props.payments.length}</strong>
                    <strong>Total: ${SumValue(props.payments).toFixed(2)}</strong>
                </div>
                {props.payments.map((payment) => (
                    <div key={payment.id} className="card border-dark">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <div className="col-md-6">
                                    <strong>Payment Method: </strong><span>{payment.paymentMethod.title}</span>
                                    <br/>
                                    <strong>Value: </strong><span>${payment.value.toFixed(2)} </span>
                                    <strong>Parcels: </strong><span>{payment.parcels}</span>
                                </div>
                                <div className="col-md-3">
                                    <Button.Danger type="button" text="Delete" onClick={() => handleDelete(payment.id)} />
                                </div>
                            </div>
                            <div className="mt-2">
                                <Accounts accounts={payment.accounts} setErrorMessage={setErrorMessage} handlePaid={props.handlePaid} handleCancelled={props.handleCancelled} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}