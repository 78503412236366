import Constants from "./Constants";
import { GetToken } from "./TokenService";
import axios from 'axios';

export const Get = async (initialDate, finalDate, type) => {
    const token = GetToken(Constants.APP_TOKEN);
    let response = {};
    try {
        response = await axios.get(Constants.API_GET_ALL_ACCOUNTS, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                "initial": initialDate,
                "final": finalDate,
                "type": type
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const GetById = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    let response = {};
    try {
        response = await axios.get(Constants.API_GET_ACCOUNT_BY_ID + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const Pay = async (accounts) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.post(Constants.API_PAY_ACCOUNTS, accounts, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Cancel = async (accounts) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.put(Constants.API_CANCEL_ACCOUNTS, accounts, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Delete = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.delete(Constants.API_DELETE_ACCOUNT + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};