import Constants from "./Constants";
import { GetToken } from "./TokenService";
import axios from 'axios';

export const GetById = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    let response = {};
    try {
        response = await axios.get(Constants.API_GET_TRANSACTION_ITEM_BY_ID + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const Post = async (transactionItem) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.post(Constants.API_CREATE_TRANSACTION_ITEM, transactionItem, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Put = async (id, transactionItem) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.put(Constants.API_UPDATE_TRANSACTION_ITEM + id, transactionItem, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};

export const Delete = async (id) => {
    const token = GetToken(Constants.APP_TOKEN);
    try {
        await axios.delete(Constants.API_DELETE_TRANSACTION_ITEM + id, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    } catch (error) {
        throw error;
    }
};