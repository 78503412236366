import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AccountStatus } from "../../utilities/Enums/AccountStatusEnum";
import { SumProperty } from "../../utilities/Methods";

export default function Progression(props) {

    const accountsPaid = props.accounts.filter((item) => item.status === AccountStatus.Paid || item.status === AccountStatus.Partial);
    const accountsCancelled = props.accounts.filter((item) => item.status === AccountStatus.Cancelled);
    const accountsAwaiting = props.accounts.filter((item) => item.status === AccountStatus.Awaiting);

    const totalPaid = SumProperty(accountsPaid, "paidValue");
    const totalDiscount = SumProperty(accountsPaid, "discount");
    const totalAwaiting = SumProperty(accountsAwaiting, "value");
    const totalRemaining = SumProperty(accountsPaid, "value") - totalPaid - totalDiscount + totalAwaiting;
    const totalCancelled = SumProperty(accountsCancelled, "value");
    const total = totalPaid + totalDiscount + totalCancelled + totalAwaiting;


    return (
        <div className="row justify-content-between align-items-center h-100">
            <div className="col-md-6 d-flex align-items-center">
                <div className="progression-circular">
                    <CircularProgressbar value={totalPaid} maxValue={total} text={"$" + totalPaid.toFixed(2)} styles={{ text: { fill: '#00C49F' }, path: { stroke: '#00C49F' } }} />
                </div>
                <h5 className="m-1 text-green">Total Paid</h5>
            </div>
            <div className="col-md-6 d-flex align-items-center">
                <div className="progression-circular">
                    <CircularProgressbar value={totalDiscount} maxValue={total} text={"$" + totalDiscount.toFixed(2)} styles={{ text: { fill: '#0088FE' }, path: { stroke: '#0088FE' } }} />
                </div>
                <h5 className="m-1 text-blue">Total Discount</h5>
            </div>
            <div className="col-md-6 d-flex align-items-center">
                <div className="progression-circular">
                    <CircularProgressbar value={totalRemaining} maxValue={total} text={"$" + totalRemaining.toFixed(2)} styles={{ text: { fill: '#FFBB28' }, path: { stroke: '#FFBB28' } }} />
                </div>
                <h5 className="m-1 text-yellow">Total Remaining</h5>
            </div>
            <div className="col-md-6 d-flex align-items-center">
                <div className="progression-circular">
                    <CircularProgressbar value={totalCancelled} maxValue={total} text={"$" + totalCancelled.toFixed(2)} styles={{ text: { fill: '#FF8042' }, path: { stroke: '#FF8042' } }} />
                </div>
                <h5 className="m-1 text-orange">Total Cancelled</h5>
            </div>
        </div>
    )
}