export const TransactionType = {
    Sale: 1,
    Purchase: 2
}

export const GetType = (type) => {
    let text = '';
    switch (type) {
        case TransactionType.Sale:
            text = "Sale";
            break;
        case TransactionType.Purchase:
            text = "Purchase";
            break;
        default:
            text = "";
            break;
    }
    return text;
}

export const GetPersonTypeRequired = (type) => {
    let text = '';
    switch (type) {
        case TransactionType.Sale:
            text = "Customer";
            break;
        case TransactionType.Purchase:
            text = "Supplier";
            break;
        default:
            text = "";
            break;
    }
    return text;
}