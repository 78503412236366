import React, { useEffect, useState } from "react";
import Loading from "../Loading";
import ErrorMessage from "../ErrorMessage";
import SuccessMessage from "../SuccessMessage";
import { Get, Delete } from "../../utilities/TransactionsService";
import { FormatDate, SumTotalValue } from "../../utilities/Methods";
import Body from "./body";
import Header from "./header";
import Button from "../Button";
import NewTransaction from "./NewTransaction";
import { GetType } from "../../utilities/Enums/TransactionTypeEnum";

export default function Transactions(props) {

    const transactionType = props.type;
    const transactionTypeString = GetType(props.type);

    const today = new Date();
    const [month, setMonth] = useState(today.getMonth() + 1);
    const [year, setYear] = useState(today.getFullYear());

    const initialFormatedDate = FormatDate(new Date(today.getFullYear(), today.getMonth(), 1));
    const finalFormatedDate = FormatDate(new Date(today.getFullYear(), today.getMonth(), new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()));

    const [transactions, setTransactions] = useState({});
    const [initialDate, setInitialDate] = useState(initialFormatedDate);
    const [finalDate, setFinalDate] = useState(finalFormatedDate);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [updates, setUpdates] = useState(0);


    useEffect(() => {
        setLoading(true);
        function getData() {
            Get(initialDate, finalDate, transactionType).then(res => {
                setTransactions(res.sort((a, b) => a.date.localeCompare(b.date)).reverse());
                setErrorMessage('');
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setErrorMessage('Error at fetching transactions from API');
                setLoading(false);
            });
        };
        getData();
    }, [updates, initialDate, finalDate, transactionType]);

    function handleDelete(id) {
        Delete(id).then(() => {
            setSuccessMessage("Successfuly deleted " + transactionTypeString);
            setErrorMessage('');
            setUpdates(updates + 1);
        }).catch(error => {
            console.log(error);
            setErrorMessage(error.response.data);
            setSuccessMessage('');
        });
    }

    function handleShowCreateModal() {
        setShowCreateModal(true);
    }
    function handleCloseCreateModal() {
        setShowCreateModal(false);
    }
    function handleCreated() {
        setErrorMessage('');
        setSuccessMessage('Successfuly created new ' + transactionTypeString);
        setUpdates(updates + 1);
    }

    function handleMonth(e) {
        setMonth(e.target.value);
    }
    function handleYear(e) {
        setYear(e.target.value);
    }
    
    function handleSearch() {
        let initial = FormatDate(new Date(year, month - 1, 1));
        let final = FormatDate(new Date(year, month, 0));
        setInitialDate(initial);
        setFinalDate(final);
    }

    return (
        loading ? <Loading text="Loading..." />
            : <main className="container">
                {showCreateModal ? <NewTransaction closeModal={handleCloseCreateModal} handleCreated={handleCreated} type={transactionType} /> : <></>}
                {errorMessage ? <ErrorMessage msg={errorMessage} /> : <></>}
                {successMessage ? <SuccessMessage msg={successMessage} /> : <></>}
                <div className="col-3">
                    <Button.Primary type="button" text={"New " + transactionTypeString} disabled={false} onClick={handleShowCreateModal} />
                </div>
                <div className="row align-items-center mt-3">
                    <div className="col-md-3">
                        <div className="d-flex align-items-center form-group mb-3">
                            <label htmlFor="year" className="form-label m-1">Year:</label>
                            <input type="number" name="year" id="year" defaultValue={year} className="form-control form-control-sm m-1" onChange={handleYear} />
                            <label htmlFor="month" className="form-label m-1">Month:</label>
                            <input type="number" name="month" id="month" min="1" max="12" defaultValue={month} className="form-control form-control-sm m-1" onChange={handleMonth} />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="d-flex align-items-center form-group mb-3">
                            <Button.Primary type="button" text="Search" disabled={false} onClick={handleSearch} />
                        </div>
                    </div>
                </div>
                {transactions.length > 0 ?
                    <table className="table">
                        <Header count={transactions.length} totalValue={SumTotalValue(transactions)} />
                        <Body transactions={transactions} onDelete={handleDelete} type={transactionTypeString} />
                    </table>
                    : <strong className="text-danger">No {transactionTypeString} found</strong>}
            </main>
    );

}