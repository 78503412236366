import React from "react";
import { GetType } from "../../../utilities/Enums/TransactionTypeEnum";
import { Link } from "react-router-dom";
import { SumProperty } from "../../../utilities/Methods";

export default function Transactions(props) {
    return (
        <table className="table mt-3">
            <thead>
                <tr>
                    <th colSpan="2">Count: {props.transactions.length}</th>
                    <th colSpan="3" className="text-end">Total: ${SumProperty(props.transactions, "totalValue").toFixed(2)}</th>
                </tr>
                <tr>
                    <th>Id</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.transactions.sort((a, b) => a.date.localeCompare(b.date)).reverse().map((transaction) => (
                    <tr key={transaction.id}>
                        <td>{transaction.id}</td>
                        <td>{GetType(transaction.type)}</td>
                        <td>{new Date(transaction.date).toLocaleDateString()}</td>
                        <td>${transaction.totalValue.toFixed(2)}</td>
                        <td>
                            <Link className='btn btn-primary btn-sm w-100' to={`/${GetType(transaction.type).toLowerCase()}-details/${transaction.id}`}>
                                Details
                            </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}